<template>
  <div :class="'import-serie-box' + (highlight ? 'highlight' : '')" @click="clicked">
    <div
      id="drop-area"
      @dragover.prevent="handleHighlight(true)"
      @dragenter.prevent="handleHighlight(true)"
      @dragleave.prevent="handleHighlight(false)"
      @drop.prevent="handleDrop"
    >
      <form action="/file-upload">
        <slot></slot>
        <input
          type="file"
          ref="file"
          accept=".csv, .tsv, .xls, .xlsx, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
          @change="handleSelect"
          style="display: none"
        />
      </form>
    </div>
    <Popup
      width="692px"
      top="9rem"
      ref="popup"
      :shown="uploading"
      @close="uploading = false"
      :style="{ opacity: uploading ? 1 : 0 }"
    >
      <div style="display: flex; flex-direction: column">
        <div style="height: 109px; width: 109px; border-radius: 54px; background-color: #ddf5ed; margin: 0 auto">
          <img style="margin-top: 28px" alt="Upload arrow" src="@/assets/svg/arrow_upload.svg" />
        </div>
        <div style="font-size: 1.7em; margin: 1em 0">
          Importing
          <b id="fileName">{{ fileName }}</b>
        </div>
        <!-- <div style="display: grid; grid-template-columns: 8em 1fr"> -->
        <div>
          <div style="margin: 1em 0">Uploading</div>
          <Process :ratio="upload_ratio"></Process>
          <div style="margin: 1em 0">Analysing</div>
          <Process :ratio="analysis_ratio"></Process>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from "../components/Popup.vue";
import client from "@/ems/ws_client";
import router from "@/router/index";
import Process from "./Process.vue";
import mixpanel from "@/ems/mixpanel";
import { freshworksEvent } from "@/ems/api_functions";

let mixPanelInstance = null;

export default {
  props: {
    team_to_share_with: Number,
    organization_id: Number,
    ignore_limit: Boolean,
  },
  data: function () {
    return {
      fileName: "file",
      highlight: false,
      progress: 0,
      messages: [],
      uploading: false,
      remote_datafile_url: "",
      upload_ratio: 0,
      analysis_ratio: 0,
      create_max_limit: -1,
    };
  },

  mounted: async function () {
    if (mixpanel) mixPanelInstance = await mixpanel.getMixpanel(this);
  },

  methods: {
    clicked: function (e) {
      this.$refs.file.click();
    },

    handleDrop: function (e) {
      this.handleFiles(e.dataTransfer.files);
    },

    handleSelect: function (e) {
      this.handleFiles(e.target.files);
    },

    handleFiles: async function (files) {
      if (!files) {
        alert("No files found");
      }
      const urlRes = await client.create(this).getAllDataFromWs("env_param", "GET", { key: "remote_datafile_url" });

      var prms = {
        name: "serie-patients:create__max-limit",
      };
      if (this.organization_id ?? 0) {
        prms.model_id = this.organization_id;
        prms.model_type = "Organization";
      }

      const maxResp = await client.create(this).query_ws2("permission_value", "GET", prms);
      this.create_max_limit = maxResp["serie-patients:create__max-limit"] || -1;

      // Make sure we have a fresh token
      await client.create(this).getAllDataFromWs("session_login", "GET");

      if (!urlRes.remote_datafile_url) {
        alert("Datafile URL not configured");
      } else if (files) {
        let all_files = Array.from(files);
        for (const file of all_files) {
          await this.uploadFile(file);
        }
      } else {
        console.error("no filez");
      }
    },

    uploadFile: async function (file) {
      this.uploading = true;
      let url = this.remote_datafile_url + "/datafile/";
      let formData = new FormData();
      let dataFileId = Math.floor(Math.random() * Math.floor(100000));
      let that = this;

      formData.append("file", file);
      formData.append("id", dataFileId);

      this.fileName = file.name;
      this.messages = [];

      let xhr = new XMLHttpRequest(),
        jwt = sessionStorage && sessionStorage.getItem("jwt");

      (xhr.onload = function () {
        if (!that.uploading) {
          mixPanelInstance && mixPanelInstance.track("upload_cancelled");
          return; // Windows was canceled
        }

        that.uploading = false;

        if (this.status != 200) {
          var clientMessages;
          //   Track upload error with mixpanel
          mixPanelInstance && mixPanelInstance.track("upload_error");
          //   render the error message
          if (this.getResponseHeader("Content-type") == "application/json") {
            const content = JSON.parse(this.responseText);
            clientMessages = content.messages;
            that.$emit("message", clientMessages);
          } else {
            clientMessages = [{ level: "danger", text: "File upload : " + this.responseText }];
            that.$emit("message", clientMessages);
          }
        } else {
          mixPanelInstance && mixPanelInstance.track("upload_success");
          let qParams = {};
          if (that.ignore_limit) qParams.ignore_limit = that.ignore_limit;
          if (parseInt(that.team_to_share_with) > 0) qParams.shared_with = that.team_to_share_with;
          if (parseInt(that.organization_id) > 0) qParams.organization_id = that.organization_id;
          router.push({
            path: "/import/" + dataFileId,
            query: qParams,
          });
        }
      }),
        (xhr.onerror = function (e) {
          mixPanelInstance && mixPanelInstance.track("upload_error");
          that.$emit("message", [
            {
              level: "danger",
              text:
                e instanceof ProgressEvent
                  ? `We found a problem during the upload of your file. Please contact our support team at support@easymedstat.com.`
                  : e.message,
            },
          ]);
        });

      xhr.ontimeout = function (e) {
        mixPanelInstance && mixPanelInstance.track("upload_timeout");
        that.$emit("message", [
          {
            level: "danger",
            text: "Hmmm, it seems that your series is too big for direct web import. Please contact our support team at support@easymedstat.com",
          },
        ]);
      };

      var teatmentCompletion = 0;
      var treatmentInterval = 500;
      var percentPerInterval = false;
      const addProgress = () => {
        if (teatmentCompletion < 100) {
          teatmentCompletion += percentPerInterval;
        }
        if (teatmentCompletion > 100) teatmentCompletion = 100;
        this.analysis_ratio = Math.round(teatmentCompletion);
      };

      xhr.upload.onprogress = (event) => {
        this.upload_ratio = Math.round((event.loaded * 100) / +event.total);
        console.log("loaded " + this.upload_ratio);
        if (this.upload_ratio > 98 && percentPerInterval == 0) {
          var totalTime = 13000 + 0.064 * event.total;
          percentPerInterval = (treatmentInterval * 100) / totalTime;
          console.log("Estim. treatment time: " + totalTime);
          setInterval(addProgress, treatmentInterval);
        }
      };

      xhr.open("POST", url);
      if (jwt) xhr.setRequestHeader("Authorization", "Bearer " + jwt);
      this.uploading = true;
      xhr.send(formData);

      mixPanelInstance && mixPanelInstance.track("upload_started");
      freshworksEvent("Import started", {
        datafile_id: dataFileId,
      });
    },

    handleHighlight: function (state) {
      this.highlight = state;
      console.log("highlight " + state);
      this.$emit("highlight", state);
    },
  },
  components: {
    Popup,
    Process,
  },
};
</script>

<style scoped>
#analysis {
  display: "inline-block";
  background-color: "#008A65";
  height: "100%";
}

#fileName {
  word-break: break-all;
}

.import-serie-box {
  padding: 15px 150px;
  border-radius: 8px;
  border: 3px dashed var(--Borders, #f2f2f2);
  background: var(--Background, #fafafa);
}

.import-serie-box.highlight {
  border-color: #00755d;
  background-color: #00755d;
  opacity: 0.1;
}

#drop-area label {
  cursor: pointer;
}
</style>
